<template>
	<el-scrollbar class="teacher-page">
		<div class="t-page">
			<div class="t-page-screen">
				<el-form inline size="small" label-width="100px">
					<el-form-item :label="$t('teacher.formItem.goodsName')">
						<el-input v-model="listQuery.goods_name"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.goodsId')">
						<el-input v-model="listQuery.goods_ids" :placeholder="$t('teacher.placeholder.goodsId')"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.goodsCate')">
						<el-cascader
							v-model="listQuery.g_cate_id"
							:options="cateOption"
							:props="{ value: 'cate_id', label: 'cate_name', checkStrictly: true }"
							popper-class="cascader-category"
							style="width: 200px;"
						/>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.student')">
						<el-input v-model="listQuery.student_name"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.tableHead.shopName')">
						<el-input v-model="listQuery.store_name"></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.tableHead.goodsStatus')">
						<el-select v-model="listQuery.goods_status" clearable style="width: 200px;">
							<el-option v-for="item in $t('teacher.option.goodsStatus')" :key="item.value" :label="item.label" :value="item.value" ></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.tableHead.goodsEnrollStatus')">
						<el-select v-model="listQuery.enroll_status" clearable style="width: 200px;">
							<el-option v-for="item in $t('teacher.option.enrollStatus')" :key="item.value" :label="item.label" :value="item.value" ></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.class')">
						<el-select v-model="listQuery.class_id" clearable  style="width: 200px;">
							<el-option v-for="(item,index) in classOption" :key="index" :label="item.name" :value="item.class_id" />
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('teacher.formItem.account')">
						<el-input v-model="listQuery.user_name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item :label="$t('teacher.tableHead.activityName')">
						<el-input v-model="listQuery.activity_name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearch"><i class="el-icon-search"></i></el-button>
						<el-button type="text" icon="el-icon-brush" @click="handleClearSearch">{{ $t('teacher.actions.clear') }}</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="t-page-table">
				<el-table :data="pageList">
					<el-table-column label="ID" prop="activity_goods_id" width="80px"></el-table-column>
					<el-table-column :label="$t('teacher.formItem.goodsName')" prop="goods_name"></el-table-column>
					<el-table-column :label="$t('teacher.formItem.belongClass')" prop="class_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.shopkeeper')" prop="user_name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.goodsStatus')">
						<template slot-scope="scope">
							<el-tag :type="scope.row.is_violation > 0 ? 2: scope.row.if_show | typeFilter({0:'warning',1:'success',2:'danger'})">{{ scope.row.is_violation > 0 ? 2: scope.row.if_show | typeFilter($t('teacher.filters.goodsStatus')) }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.goodsEnrollStatus')">
						<template slot-scope="scope">
							{{ scope.row.enroll_status | typeFilter($t('teacher.filters.enrollStatus')) }}
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.activityName')" prop="name"></el-table-column>
					<el-table-column :label="$t('teacher.tableHead.activityPrice')">
						<template slot-scope="scope">
							<template v-if="parseFloat(scope.row.activity_price) > 0">￥{{ scope.row.activity_price | priceFilter() }}</template>
						</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.fixedPrice')">
						<template slot-scope="scope">￥{{ scope.row.price | priceFilter() }}</template>
					</el-table-column>
					<el-table-column :label="$t('teacher.tableHead.actions')">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleViews(scope)">{{ $t('teacher.actions.view') }}</el-button>
						</template>
					</el-table-column>
				</el-table>
				<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
					@pagination="getList"></pagination>
					<el-dialog :visible.sync="dialogVisible" width="960px" custom-class="dialog-tabs">
				<div class="dialog-wrap">
					<div class="dialog-head">{{ $t('teacher.market.goodsBaseInfo') }}</div>
						<el-form v-if="dialogGoods.goods_info" label-width="100px" size="mini">
						<el-form-item :label="$t('teacher.formItem.goodsName')"><a :href="$itemBasePath+'/item/'+dialogGoods.goods_info.goods_id" target="_blank" class="item-link">{{ dialogGoods.goods_info.goods_name }}</a></el-form-item>
						<el-form-item :label="$t('teacher.formItem.goodsId')">{{ dialogGoods.goods_info.goods_id }}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.cate2')">{{ dialogGoods.goods_info.cate_name }}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.shopPrice')">{{ dialogGoods.goods_info.price | priceFilter() }}{{ $t('teacher.unit.yuan') }}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.onlineStock')">{{ dialogGoods.goods_info.stock }}{{ $t('teacher.unit.piece') }}</el-form-item>
						</el-form>
						<div class="dialog-head">{{ $t('teacher.market.enrollBaseInfo') }}</div>
						<el-form v-if="dialogGoods.activity_info" label-width="100px" size="mini">
						<el-form-item :label="$t('teacher.formItem.enrollSpecs')">{{ $t('teacher.market.allSku') }}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.goodsMainpic')"><img :src="dialogGoods.goods_info.default_image" class="goods-pic" alt=""></el-form-item>
						<el-form-item :label="$t('teacher.formItem.limitQuantity')">{{ dialogGoods.activity_info.limit_quantity }}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.activityPrice')">{{ dialogGoods.activity_info.activity_price | priceFilter() }}{{ $t('teacher.unit.yuan') }}</el-form-item>
						<el-form-item :label="$t('teacher.formItem.enrollStock')">{{ dialogGoods.activity_info.activity_stock }}{{ $t('teacher.unit.piece') }}</el-form-item>
						</el-form>
					</div>
				</el-dialog>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
import Pagination from '@/components/seller/pagination'
import { fetchClass, fetchMarketCate, fetchMarketGoods, fetchMarketGoodsInfo } from '@/api/teacher'
const defaultQuery = {
  goods_name: '',
  goods_ids: '',
  g_cate_id: '',
  student_name: '',
  store_name: '',
  goods_status: '',
  enroll_status: '',
  school_id: '',
  class_id: '',
  user_name: ''
}
export default {
	components: { Pagination },
	computed: {
		...mapGetters([
			'userInfo'
		])
	},
	data() {
		return {
			cateOption: [],
			classOption: [],
			listQuery: Object.assign({}, defaultQuery),
			pageList: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 10,
			dialogVisible: false,
			dialogGoods: {}
		}
	},
	created () {
		this.getData()
	},
	methods: {
		async getData() {
			try {
				const classData = await fetchClass({ page_index: 0, page_size: 1000 })
				this.classOption = classData.data.list
				const cate = await fetchMarketCate()
				this.cateOption = cate.data.list
			} catch (error) {
				console.log(error)
			}
			this.getList()
		},
		getList() {
			const params = {
				...this.listQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchMarketGoods(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
			})
		},
		handleSearch() {
			this.pageCurr = 1
			this.getList()
		},
		handleClearSearch() {
			this.listQuery = Object.assign({}, defaultQuery)
			this.pageCurr = 1
			this.getList()
		},
		handleViews({ row }) {
			fetchMarketGoodsInfo({ activity_goods_id: row.activity_goods_id }).then(response => {
				const { data } = response
				this.dialogGoods = data
				this.dialogVisible = true
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.t-page {
	padding: 24px;
	.t-page-screen {
		.el-button--text {
			color: #777;
		}
	}
	.t-page-table {
		::v-deep .el-table {
			th.el-table__cell {
				background-color: $--seller-thead-background-1;
			}
		}
	}
}
.dialog-wrap {
  .dialog-head {
    line-height: 1;
    padding-left: 6px;
    font-size: 16px;
    border-left: 4px solid #f40;
    margin: 20px 0;
    font-weight: bold;
  }
  .goods-pic {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .item-link {
    color: #3d7fff;
  }
}
</style>